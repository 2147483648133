module.exports = {
  redirectBaseUrl: '',
  cognito: {
    userPoolId: 'eu-west-2_hKWn22Bli',
    userPoolWebClientId: '78v10aucramc30457f0etcbkqu',
    platformSubdomain: '',
    cognitoSubdomain: 'platform-kaluza',
    userManagementURL: 'https://user-management.gridsvc.net/api',
    loginRedirectDestination: 'https://platform.kaluza.com/callback',
    logoutRedirectDestination: 'https://platform.kaluza.com/'
  },
  cookies: {
    domain: 'platform.kaluza.com'
  }
};
